export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 43,
  w: 60,
  h: 60,
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [30, 30, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0.203, 2.672, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [25, 25], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              hd: false
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [0]
                  },
                  { t: 40, s: [100] }
                ],
                ix: 2
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.207843137255, 0.325490196078, 0.858823529412, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0.203, 2.672], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 43,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [30, 30, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0.203, 2.672, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: { a: 0, k: [25, 25], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.858823529412, 0.858823529412, 0.858823529412, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0.203, 2.672], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 43,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [30, 30, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-4.836, -1.169, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [55.995, 55.995], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 8, ix: 4 },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.960784313725, 0.964705882353, 0.960784313725, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-4.836, -1.169], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 43,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
